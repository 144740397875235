import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class BranchService {

  /**
   * บันทึก สาขา/ทีม
   */
  save(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'branch', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  /**
   * บันทึกแก้ไข สาขา/ทีม
   */
  update(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'branch/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  gets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'branch')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

}

export default new BranchService()
